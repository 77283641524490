import { FC, useContext } from "react"
import Button from "src/views/components/common/Button/Button"
import { ResumeApplyContext } from "src/context/ResumeApplyProvider"
import useVacancy from "src/server/queryHooks/useVacancy"

interface Props {
  vacancy_id: number
  size?: any
}

const ApplyButton: FC<Props> = ({ vacancy_id, size = "big" }) => {
  const { setVacancyId } = useContext(ResumeApplyContext)
  const { vacancySavedAppliedStatus } = useVacancy()
  const isApplied = vacancySavedAppliedStatus?.data?.applied[vacancy_id]

  return (
    <Button
      intent="primary"
      size={size}
      text={isApplied ? "გაგზავნილია" : "გაგზავნა"}
      disabled={isApplied}
      rounded="full"
      weight="bold"
      textClassName="text-sm"
      onClick={() => setVacancyId(vacancy_id)}
    />
  )
}

export default ApplyButton
