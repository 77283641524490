import * as yup from "yup"

export const MAX_FILE_SIZE: number = 1024 * 1024 * 10 // 5  mb

export const resumeApplySchema = yup.object().shape({
  name: yup.string().required("სახელი, გვარი აუცილებელია"),
  profession: yup.string().required("პროფესია აუცილებელია"),
  email: yup.string().required("ელფოსტის მისამართი აუცილებელია").email("ელფოსტის მისამართის ფორმატი არასწორია"),
  mobile: yup.number().typeError("ნომერი აუცილებელია").required("ნომერი აუცილებელია").positive("არასწორი ფორმატი"),
  cv: yup.lazy(value =>
    value
      ? yup.mixed().test("fileSize", "ფაილი უნდა იყოს 10mb ზე ნაკლები", () => value.size <= MAX_FILE_SIZE)
      : yup.mixed().required("ფაილის ატვირთვა აუცილებელია")
  ),
  sms: yup.lazy(value => (value ? yup.string() : yup.string().nullable()))
})
