import { cx } from "class-variance-authority"
import { useEffect, useRef } from "react"
import Typography from "src/views/components/typography"

interface Props {
  length?: number
  otp: any[]
  setOtp: any
  onOtpSubmit?: any
  invalidCoreError?: boolean
}
const OtpInput = ({ length = 4, onOtpSubmit = () => {}, otp, setOtp, invalidCoreError }: Props) => {
  // const inputRefs = useRef<HTMLInputElement[]>([])
  const inputRefs = useRef<any[]>([])

  const handleChange = (index: number, e: any) => {
    const value = e.target.value
    if (isNaN(value)) return

    const newOtp = [...otp]
    // Allow only one character input
    newOtp[index] = value.substring(value.length - 1)
    setOtp(newOtp)

    // Submit trigger
    const combinedOtp = newOtp.join("")
    if (combinedOtp.length === length) {
      onOtpSubmit(combinedOtp)
    }

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handleClick = (index: number) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index].select()
    }

    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus()
    }
  }

  const handleKeyDown = (index: number, e: any) => {
    // Move to previous input on backspace
    if (e.key === "Backspace" && !otp[index] && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus()
    }
  }

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus()
    }
  }, [])

  return (
    <div>
      {invalidCoreError && (
        <Typography type="small" weight="medium" className="block pb-4 text-error-100">
          არასწორი კოდი
        </Typography>
      )}
      <div className="flex items-center justify-center gap-4">
        {otp.map((value, index) => {
          return (
            <input
              className={cx(
                "h-[62px] w-[56px] rounded-xl border  text-center text-lg focus:outline-2 focus:outline-primary-100",
                invalidCoreError ? "border-error-100" : "border-neutral-40"
                // codeError ? 'border-error-100' : 'border-neutral-40'
              )}
              ref={input => {
                inputRefs.current[index] = input!
              }}
              key={index}
              type="number"
              value={value}
              onChange={e => handleChange(index, e)}
              onClick={e => handleClick(index)}
              onKeyDown={e => handleKeyDown(index, e)}
              id="otpInput"
            />
          )
        })}
      </div>
    </div>
  )
}

export default OtpInput
