// components/ApplyModalWrapper.js
import { Dialog } from "@headlessui/react"
import { useContext } from "react"
import Typography from "src/views/components/typography"
import { ResumeApplyContext } from "src/context/ResumeApplyProvider"
import IconButton from "src/views/components/common/Button/IconButton"

interface Props {
  children: React.ReactNode
  title?: string
  subtitle?: string
  showBackButton?: boolean
  textLeft?: boolean
}
const ModalStep = ({ subtitle, textLeft = false, children, title, showBackButton }: Props) => {
  const { closeAndResetModal, setResumeModalStep } = useContext(ResumeApplyContext)

  return (
    <>
      {showBackButton && (
        <div className="absolute left-4 top-4 opacity-70 hover:opacity-100">
          <IconButton
            src="/images/arrow_back.svg"
            onClick={() => setResumeModalStep((prevStep: number) => prevStep - 1)}
          />
        </div>
      )}

      <Dialog.Title as="div">
        <Typography type="h4" className={`${textLeft && "text-left"}`}>
          {title}
        </Typography>
      </Dialog.Title>

      <Typography type="body" className="mt-3 px-8" color="light" weight="medium">
        {subtitle}
      </Typography>
      <div className="z-10 flex flex-1 flex-col">{children}</div>
    </>
  )
}

export default ModalStep
