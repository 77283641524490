import { useMutation } from "@tanstack/react-query"
import { cx } from "class-variance-authority"
import React, { useContext, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import Button from "src/views/components/common/Button/Button"
import ControlledField from "src/views/components/common/form-controll/Controlled/ControlledField"
import Typography from "src/views/components/typography"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import ModalStep from "../ModalStep"

import Image from "next/image"
import { getCheckApplicantCredentials, getCheckApplicantCredentialsInterface } from "src/server/queryHooks/useResumeApi"
import { ResumeApplyContext } from "../../../../context/ResumeApplyProvider"
import { postSendOTP } from "../../../../server/queryHooks/useGlobal"

type FormData = {
  name: string
  mobile: number
  profession: string
  email: string
  cv?: any
  sms?: string | null
}

const ResumeStepGuestForm = () => {
  const [serverErrMsg, setServerErrMsg] = useState<string | undefined>()

  const { vacancy_id, setResumeModalStep } = useContext(ResumeApplyContext)
  const [counter, setCounter] = useState(0)

  React.useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000)
      setServerErrMsg(`ხელახლა გაგზავნა შესაძლებელია ${counter} წამში`)
    } else {
      setServerErrMsg("")
    }
  }, [counter])

  const checkApplicantCredentialsMutation = useMutation({
    mutationFn: (data: getCheckApplicantCredentialsInterface) => getCheckApplicantCredentials(data),
    mutationKey: [QUERY_KEYS.GET_CHECK_APPLICANT_CREDENTIALS],
    // This API will return success on Error
    onSuccess: (data, variables) => {
      //   Means user has not sent duplicate CV request
      if (data.used_credentials.length === 0) {
        postSendOTPMutation.mutate({ phone: Number(variables.mobile) })
      }

      //   Same email on vacancy
      if (data.used_credentials.includes("email")) {
        setError("email", { type: "custom", message: "ვაკანსიაზე ეს ელფოსტა უკვე გამოყენებულია" })
      }

      //   Same mobile on vacancy
      if (data.used_credentials.includes("mobile")) {
        setError("mobile", { type: "custom", message: "ვაკანსიაზე ეს ნომერი უკვე გამოყენებულია" })
      }
    }
  })

  const postSendOTPMutation = useMutation({
    mutationKey: [QUERY_KEYS.POST_SEND_OTP],
    mutationFn: postSendOTP,
    onError: (err, _, context) => {
      if (err instanceof Error) {
        const error: any = err

        if (error?.response?.data?.message?.error_data?.phone[0] === "invalid_format") {
          setError("mobile", { type: "custom", message: "მობილურის არასწორი ფორმატი" })
        }
        if (error?.response?.data?.message?.error_data?.phone[0] === "timeout") {
          setCounter(error?.response?.data?.message?.error_data?.timeout[0])
          setServerErrMsg(
            `ხელახლა გაგზავნა შესაძლებელია ${error?.response?.data?.message?.error_data?.timeout[0]} წამში`
          )
        }
      }
    },
    onSuccess: () => {
      // Move to last step of modal
      setResumeModalStep(3)
    }
  })

  const onSubmit = (data: FormData) => {
    // Check if already applied
    const { email, mobile } = data
    if (vacancy_id) {
      checkApplicantCredentialsMutation.mutate({ email, mobile: `${mobile}`, vacancy_id })
    }
  }

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    trigger,
    formState: { errors }
  } = useFormContext<FormData>()

  const cv = watch("cv")
  const fileInputRef = useRef<any>()
  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <ModalStep title="რეზიუმეს გაგზავნა" subtitle="შეავსე ინფორმაცია" showBackButton>
      <form className="mt-6 flex flex-1 flex-col gap-4 " onSubmit={handleSubmit(onSubmit)}>
        <ControlledField control={control} label="სახელი, გვარი" name="name" />
        <ControlledField control={control} label="პროფესია" name="profession" />
        <ControlledField control={control} label="ელფოსტის მისამართი" name="email" />
        <ControlledField control={control} type="tel" label="მობილური" name="mobile" />

        <input
          type="file"
          accept=".pdf,.docx,.doc,.txt"
          hidden
          name="cv"
          id="fileInput"
          ref={fileInputRef}
          onChange={e => {
            const selectedFile = e.target.files && e.target.files[0]
            const fileType = selectedFile?.type // MIME type
            const validExtensions = [
              ".pdf",
              ".docx",
              ".doc",
              ".txt",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/pdf",
              "text/plain"
            ]

            const isValidFileType = validExtensions.some(ext => fileType && fileType.endsWith(ext))

            if (isValidFileType && e.target.files) {
              setValue("cv", e.target.files[0])
              trigger("cv")
            } else {
              setValue("cv", undefined)
              setError("cv", { type: "custom", message: "არასწორი ფორმატი" })
            }
          }}
        />

        <div
          className={cx(
            "flex w-full cursor-pointer items-start gap-3 self-start rounded-md border p-4 py-6",
            errors.cv ? "border-error-100" : "border-neutral-20"
          )}
          onClick={openFileInput}
        >
          <Image src="/images/cv_upload.svg" width={33} height={33} alt="cv upload" />

          <div className="flex flex-col items-start gap-1">
            <Typography type="button" weight="bold" className={cx(errors.cv ? "text-error-100" : "text-primary-100")}>
              {cv ? <p>Selected file: {cv.name}</p> : "ატვირთე რეზიუმე"}
            </Typography>
            <Typography type="small" className="text-secondary-50">
              pdf, docx, doc, txt
            </Typography>
          </div>
        </div>
        {errors.cv && (
          <Typography type="small" className="-mt-3 pl-4 text-left text-error-100">
            {errors.cv.message}
          </Typography>
        )}
        <Button
          intent="primary"
          type="submit"
          text="გაგზავნა"
          className="mt-auto w-full max-w-full"
          loading={checkApplicantCredentialsMutation.isLoading || postSendOTPMutation.isLoading}
        />

        {serverErrMsg && (
          <Typography className="text-error-100" type="body">
            {serverErrMsg}
          </Typography>
        )}
      </form>
    </ModalStep>
  )
}

export default ResumeStepGuestForm
