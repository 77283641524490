// Nice job
export const renderVacancySalaryPeriod = (salary_period: string | null, salary_type?: string | null) => {
  if (salary_type === "negotiable") return ""

  if (salary_period === "monthly") {
    return "/თვეში"
  }
  if (salary_period === "daily") {
    return "/დღეში"
  }
  if (salary_period === "hourly") {
    return "/საათში"
  }
  return ""
}
