import { Transition } from "@headlessui/react"
import { cx } from "class-variance-authority"
import Image from "next/image"
import Link from "next/link"
import { useEffect, useState } from "react"
import Typography from "src/views/components/typography"
import Spinner from "src/views/components/UI/loaders/Spinner"
import ApplyButton from "src/views/components/Vacancy/ApplyButton"
import useVacancy from "src/server/queryHooks/useVacancy"
import VacancyShare from "../VacancyShare"

type Props = {
  divRef: React.RefObject<HTMLDivElement>
  title: string
  companyTitle: string
  vacancy_id: number
  location: string
}

const VacancyItemScrollHeader = ({ divRef, title, vacancy_id, companyTitle, location }: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const [showSocialMedia, setShowSocialMedia] = useState(false)
  const { vacancySavedAppliedStatus, saveVacancyMutation, deleteSavedVacancyMutation } = useVacancy()
  const isSaved = vacancySavedAppliedStatus.data?.saved[vacancy_id]

  const loadingSaved = saveVacancyMutation.isLoading || deleteSavedVacancyMutation.isLoading

  const toggleSaveVacancy = () => {
    if (!loadingSaved) {
      isSaved ? deleteSavedVacancyMutation.mutate(vacancy_id) : saveVacancyMutation.mutate(vacancy_id)
    }
  }

  const handleScroll = () => {
    setShowSocialMedia(false)
  }

  useEffect(() => {
    const parentElement = document.getElementById("scrollable-parent")

    if (parentElement) {
      parentElement.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (parentElement) {
        parentElement.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    if (window.innerWidth < 768) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.boundingClientRect.top < 50)
        },
        { threshold: [1.0] }
      )

      if (divRef.current) {
        observer.observe(divRef.current)
      }

      return () => {
        if (divRef.current) {
          observer.unobserve(divRef.current)
        }
      }
    } else {
      const handleScroll = () => {
        const divElement = divRef.current

        if (divElement) {
          const { scrollTop } = divElement

          if (scrollTop >= 180) {
            setIsVisible(true)
          } else {
            setIsVisible(false)
          }
        }
      }

      const divElement = divRef.current
      divElement?.addEventListener("scroll", handleScroll)

      return () => {
        divElement?.removeEventListener("scroll", handleScroll)
      }
    }
  }, [isVisible, divRef])

  // if (!isVisible) return null
  return (
    <Transition
      as="div"
      id="scrollable-parent"
      show={isVisible}
      enter="transition-transform ease-out duration-300"
      enterFrom="translate-y-[100%] md:translate-y-[-100%]"
      enterTo="md:translate-y-0"
      leave="transition-transform ease-in duration-200"
      leaveFrom="md:translate-y-0"
      leaveTo="translate-y-[100%] md:translate-y-[-100%]"
      className="vacancy-content-bar absolute bottom-0 left-0 flex h-20 w-full items-center justify-between border-b border-neutral-40 bg-white px-6 py-4 shadow-header md:bottom-auto md:top-0"
    >
      <div className="hidden md:block">
        <div>
          <Typography type="h4" className="line-clamp-1">
            {title}
          </Typography>
          <div className="mt-2 flex items-center gap-2">
            <Typography type="text" color="dark" className="line-clamp-1">
              {companyTitle}
            </Typography>
            <Image src="/images/eclipse.svg" width={4} height={4} alt="eclipse" />
            <Typography type="text" className="line-clamp-1">
              {location}
            </Typography>
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <div className="flex">
          <Typography type="h4" className="line-clamp-1">
            {title}
          </Typography>
        </div>
      </div>
      <div className="block flex flex-shrink-0 items-center gap-3 md:hidden">
        <div
          className={cx(
            "flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-full border border-secondary-20 transition-all"
          )}
          onClick={() => toggleSaveVacancy()}
        >
          {loadingSaved ? (
            <Spinner />
          ) : isSaved ? (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.0908 2.63647H5.90901C5.00901 2.63647 4.27264 3.37284 4.27264 4.27284V17.3637L9.99992 14.9092L15.7272 17.3637V4.27284C15.7272 3.37284 14.9908 2.63647 14.0908 2.63647Z"
                fill="#A155B9"
              />
            </svg>
          ) : (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="#fff" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.0908 2.63647H5.90901C5.00901 2.63647 4.27264 3.37284 4.27264 4.27284V17.3637L9.99992 14.9092L15.7272 17.3637V4.27284C15.7272 3.37284 14.9908 2.63647 14.0908 2.63647ZM14.0908 14.9092L9.99992 13.1256L5.90901 14.9092V5.09102C5.90901 4.64102 6.27719 4.27284 6.72719 4.27284H13.2726C13.7226 4.27284 14.0908 4.64102 14.0908 5.09102V14.9092Z"
                fill="#909090"
              />
            </svg>
          )}
        </div>
        <ApplyButton vacancy_id={vacancy_id} size="normal" />
      </div>
      <div className="hidden flex-shrink-0 items-center gap-3 md:flex">
        <ApplyButton vacancy_id={vacancy_id} size="normal" />

        <div
          className={cx(
            "flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-full border border-secondary-20 transition-all"
          )}
          onClick={() => toggleSaveVacancy()}
        >
          {loadingSaved ? (
            <Spinner />
          ) : isSaved ? (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.0908 2.63647H5.90901C5.00901 2.63647 4.27264 3.37284 4.27264 4.27284V17.3637L9.99992 14.9092L15.7272 17.3637V4.27284C15.7272 3.37284 14.9908 2.63647 14.0908 2.63647Z"
                fill="#A155B9"
              />
            </svg>
          ) : (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="#fff" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.0908 2.63647H5.90901C5.00901 2.63647 4.27264 3.37284 4.27264 4.27284V17.3637L9.99992 14.9092L15.7272 17.3637V4.27284C15.7272 3.37284 14.9908 2.63647 14.0908 2.63647ZM14.0908 14.9092L9.99992 13.1256L5.90901 14.9092V5.09102C5.90901 4.64102 6.27719 4.27284 6.72719 4.27284H13.2726C13.7226 4.27284 14.0908 4.64102 14.0908 5.09102V14.9092Z"
                fill="#909090"
              />
            </svg>
          )}
        </div>

        <Link
          className="flex h-9 w-9 cursor-pointer  items-center justify-center rounded-full border border-secondary-20"
          href={`/vacancy/${vacancy_id}`}
          target="_blank"
        >
          <Image src="/images/open_in_new.svg" width={18} height={18} alt="bookmark_border" />
        </Link>
        <div className="flex flex-col items-center">
          <div
            className={cx(
              "flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-full border border-secondary-20 transition-all"
            )}
            onClick={() => setShowSocialMedia(!showSocialMedia)}
          >
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.5 11.09C10.93 11.09 10.42 11.315 10.03 11.6675L4.6825 8.55503C4.72 8.38253 4.75 8.21003 4.75 8.03003C4.75 7.85003 4.72 7.67753 4.6825 7.50503L9.97 4.42253C10.375 4.79753 10.9075 5.03003 11.5 5.03003C12.745 5.03003 13.75 4.02503 13.75 2.78003C13.75 1.53503 12.745 0.530029 11.5 0.530029C10.255 0.530029 9.25 1.53503 9.25 2.78003C9.25 2.96003 9.28 3.13253 9.3175 3.30503L4.03 6.38753C3.625 6.01253 3.0925 5.78003 2.5 5.78003C1.255 5.78003 0.25 6.78503 0.25 8.03003C0.25 9.27503 1.255 10.28 2.5 10.28C3.0925 10.28 3.625 10.0475 4.03 9.67253L9.37 12.7925C9.3325 12.95 9.31 13.115 9.31 13.28C9.31 14.4875 10.2925 15.47 11.5 15.47C12.7075 15.47 13.69 14.4875 13.69 13.28C13.69 12.0725 12.7075 11.09 11.5 11.09ZM11.5 2.03003C11.9125 2.03003 12.25 2.36753 12.25 2.78003C12.25 3.19253 11.9125 3.53003 11.5 3.53003C11.0875 3.53003 10.75 3.19253 10.75 2.78003C10.75 2.36753 11.0875 2.03003 11.5 2.03003ZM2.5 8.78003C2.0875 8.78003 1.75 8.44253 1.75 8.03003C1.75 7.61753 2.0875 7.28003 2.5 7.28003C2.9125 7.28003 3.25 7.61753 3.25 8.03003C3.25 8.44253 2.9125 8.78003 2.5 8.78003ZM11.5 14.045C11.0875 14.045 10.75 13.7075 10.75 13.295C10.75 12.8825 11.0875 12.545 11.5 12.545C11.9125 12.545 12.25 12.8825 12.25 13.295C12.25 13.7075 11.9125 14.045 11.5 14.045Z"
                fill="#909090"
              />
            </svg>
          </div>
          {showSocialMedia && (
            <VacancyShare
              vacancyId={vacancy_id}
              vacancyTitle={title}
              onClose={() => setShowSocialMedia(false)}
              top="20"
              right="4"
            />
          )}
        </div>
      </div>
    </Transition>
  )
}

export default VacancyItemScrollHeader
